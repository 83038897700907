"use client"

import LoadingAnimation from "@/components/ui/loading/LoadingAnimation"
import React, { createContext, useContext, useMemo, useState } from "react"

interface LoadingContextProps {
  loading: boolean
  setLoading: (loading: boolean) => void
}

const LoadingContext = createContext<LoadingContextProps | undefined>(undefined)

export const useLoading = (): LoadingContextProps => {
  const context = useContext(LoadingContext)

  if (!context) {
    throw new Error("useLoading must be used within a LoadingProvider")
  }
  return context
}

export const LoadingProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [loading, setLoading] = useState(false)

  const value = useMemo(() => ({ loading, setLoading }), [loading])

  return (
    <LoadingContext.Provider value={value}>
      {children}
      {loading && <LoadingAnimation text="Carregando..." />}
    </LoadingContext.Provider>
  )
}
