"use client"

import { useOffline } from "@/contexts/OfflineContext"
import { useMessageSnackbarStore } from "@/stores/ui/useMessageSnackbar"
import { useEffect, useState } from "react"

export default function NetworkStatus() {
  const { isOffline, setIsOffline } = useOffline()
  const { errorMessage, infoMessage } = useMessageSnackbarStore()
  const [wasOffline, setWasOffline] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      const updateOnlineStatus = () => {
        const onlineStatus = navigator.onLine

        if (onlineStatus) {
          if (wasOffline) {
            infoMessage("Você está online novamente.")
            setWasOffline(false)
          }
          setIsOffline(false)
        } else {
          setWasOffline(true)
          setIsOffline(true)
        }
      }

      window.addEventListener("online", updateOnlineStatus)
      window.addEventListener("offline", updateOnlineStatus)
      updateOnlineStatus()

      return () => {
        window.removeEventListener("online", updateOnlineStatus)
        window.removeEventListener("offline", updateOnlineStatus)
      }
    }
  }, [wasOffline, infoMessage, setIsOffline])

  useEffect(() => {
    if (isOffline) {
      errorMessage(
        "Sem conexão com a internet. Algumas funcionalidades podem não estar disponíveis.",
      )
      const interval = setInterval(() => {
        errorMessage(
          "Você está offline. Algumas funcionalidades podem não estar disponíveis.",
        )
      }, 120000)

      return () => clearInterval(interval)
    }
  }, [isOffline, errorMessage])

  return null
}
