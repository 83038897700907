import { useAuth } from "@/contexts/AuthProvider"
import { CurriculumOutput } from "@/interfaces/curriculum/CurriculumOutput"
import { fetcher } from "@/libs/api"
import useSWR from "swr"

/**
 * Hook to get the curriculum by token
 * @param suppressErrorSnackbar boolean to suppress error snackbar default true
 * @returns curriculum: CurriculumOutput
 * @returns isLoadingCurriculum: boolean
 * @returns isErrorCurriculum: boolean
 * @returns mutateCurriculum: (data?: CurriculumOutput | Promise<CurriculumOutput> | MutatorCallback<CurriculumOutput> | undefined, shouldRevalidate?: boolean | undefined) => Promise<CurriculumOutput | undefined>
 */
export function useCurriculumByToken(suppressErrorSnackbar = true) {
  const { isLoggedIn } = useAuth()
  const { data, error, mutate } = useSWR<CurriculumOutput>(
    isLoggedIn ? `${process.env.NEXT_PUBLIC_EMPLOYMENT_SERVICE_PATH}/curriculum` : null,
    (url) => fetcher(url, { suppressErrorSnackbar }),
    {
      onErrorRetry: (error) => {
        if (error.status === 404) return
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      errorRetryCount: 0,
    },
  )

  return {
    curriculum: data,
    isLoadingCurriculum: !data && !error,
    isErrorCurriculum: !!error,
    mutateCurriculum: mutate,
  }
}
