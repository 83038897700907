"use client"

import NetworkStatus from "@/app/NetworkStatus"
import WidgetLibras from "@/components/ui/accessibility/WidgetLibras"
import { ConversationAI } from "@/components/ui/agent/Conversation"
import { MessageSnackbarContainer } from "@/components/ui/message/MessageSnackbarContainer"
import { AuthProvider } from "@/contexts/AuthProvider"
import { LoadingProvider } from "@/contexts/LoadingContext"
import { ModalProvider } from "@/contexts/ModalContext"
import { OfflineProvider } from "@/contexts/OfflineContext"
import { SWRConfigProvider } from "@/contexts/SWRConfigContext"
import { ROUTES } from "@/interfaces/constants/routes"
import {
  ADMINtheme,
  FUNTRABTheme,
  IEPtheme,
  SEMADESCtheme,
  applicantsTheme,
  baseTheme,
  employerTheme,
} from "@/styles/theme"
import { isDevelopmentMode } from "@/utils/environmentUtils"
import { CssBaseline, GlobalStyles } from "@mui/material"
import { AppRouterCacheProvider } from "@mui/material-nextjs/v14-appRouter"
import { Theme, ThemeProvider } from "@mui/material/styles"
import { usePathname } from "next/navigation"
import Script from "next/script"
import React, { memo } from "react"

function InitialTheme(): Theme {
  const pathname = usePathname()

  const themeMap: Readonly<Record<string, Theme>> = {
    [ROUTES.PAINEL.CANDIDATE]: applicantsTheme,
    [ROUTES.PAINEL.STUDENT]: applicantsTheme,
    [ROUTES.PAINEL.COMPANY]: employerTheme,
    [ROUTES.PAINEL.FUNTRAB]: FUNTRABTheme,
    [ROUTES.PAINEL.SEMADESC]: SEMADESCtheme,
    [ROUTES.PAINEL.IEP]: IEPtheme,
    [ROUTES.PAINEL.ADMIN]: ADMINtheme,
    [ROUTES.PAINEL.USER_INFO]: applicantsTheme,
  }

  for (const route in themeMap) {
    if (pathname.startsWith(route)) {
      return themeMap[route]
    }
  }

  return baseTheme
}

export function RootClientLayout({
  children,
}: Readonly<{
  children: React.ReactNode
}>) {
  const gtmId = process.env.NEXT_PUBLIC_G_TAG_MANAGER_ID ?? ""
  return (
    <>
      <noscript>
        <iframe
          title="Google Tag Manager (noscript)"
          id="gtm-body"
          src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
          loading="lazy"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      <OfflineProvider>
        <NetworkStatus />
        <AppRouterCacheProvider>
          <ThemeProvider theme={InitialTheme()}>
            <CssBaseline />
            <GlobalStyles
              styles={{
                "@keyframes spin": {
                  "0%": { transform: "rotate(0deg)" },
                  "100%": { transform: "rotate(-360deg)" },
                },
                ".sync-animating": {
                  animation: "spin-reverse 1s linear infinite",
                },
                ".sync-success": {
                  color: "#4CAF50",
                  animation: "fadeOut 3s ease-out",
                },
                ".sync-error": {
                  color: "#F44336",
                  animation: "fadeOut 3s ease-out",
                },
                "@keyframes fadeOut": {
                  "0%": { opacity: 1 },
                  "100%": { opacity: 0 },
                },
                ".vertical-center": {
                  display: "flex",
                  alignItems: "center",
                },

                ".network-indicator": {
                  fontSize: "24px",
                  color: "#ffcc00",
                  transition: "color 0.3s ease-in-out",
                },
              }}
            />
            <SWRConfigProvider>
              <AuthProvider>
                <LoadingProvider>
                  <ModalProvider>
                    <div id="message-spot" aria-live="polite"></div>
                    {children}
                    <MessageSnackbarContainer />
                    {process.env.NEXT_PUBLIC_CHATAI_OPEN === "true" && <ConversationAI />}
                  </ModalProvider>
                </LoadingProvider>
              </AuthProvider>
            </SWRConfigProvider>
          </ThemeProvider>
        </AppRouterCacheProvider>
      </OfflineProvider>
      {!isDevelopmentMode() && (
        <>
          <Script
            src="https://cdn.userway.org/widget.js"
            data-account="nZyGsommUy"
            async
          ></Script>
          <WidgetLibras />
        </>
      )}
    </>
  )
}

export default memo(RootClientLayout)
