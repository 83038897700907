"use client"

import { useMessageSnackbarStore } from "@/stores/ui/useMessageSnackbar"

import { MessageSnackbar } from "./MessageSnackbar"

export function MessageSnackbarContainer() {
  const messages = useMessageSnackbarStore((state) => state.messages)

  return (
    <>
      {messages.map((message, index) => (
        <MessageSnackbar
          key={message.id}
          message={message}
          offset={messages.length - index - 1}
        />
      ))}
    </>
  )
}
