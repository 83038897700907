"use client"

import { fetcher } from "@/libs/api"
import { SWRConfig } from "swr"
import type { Cache, SWRConfiguration } from "swr"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const swrCache = new Map<string, any>()

export function SWRConfigProvider({ children }: Readonly<{ children: React.ReactNode }>) {
  return (
    <SWRConfig
      value={
        {
          fetcher,
          keepPreviousData: true,
          dedupingInterval: 60000,
          revalidateOnFocus: false,
          provider: () => {
            return {
              get: (key: string) => swrCache.get(key),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              set: (key: string, value: any) => swrCache.set(key, value),
              delete: (key: string) => swrCache.delete(key),
              keys: () => swrCache.keys(),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as Cache<any>
          },
          onError: (err) => {
            if (process.env.NODE_ENV !== "production") {
              console.error(err)
            }
          },
          onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
            if (retryCount >= 3) return
            if (error.status === 404) return
            setTimeout(() => revalidate({ retryCount }), 5000)
          },
        } as SWRConfiguration
      }
    >
      {children}
    </SWRConfig>
  )
}
