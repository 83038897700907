"use client"

import { getJwtToken } from "@/cookies/jwtCookieHelper"
import React, { createContext, useContext, useEffect, useMemo, useState } from "react"

interface AuthContextData {
  isLoggedIn: boolean | null
  isLoading: boolean
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean | null>>
}

interface AuthProviderProps {
  children: React.ReactNode
}

const AuthContext = createContext<AuthContextData | undefined>(undefined)

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const jwtToken = getJwtToken()
    setIsLoggedIn(!!jwtToken)
    setIsLoading(false)
  }, [])

  const contextValue = useMemo(
    () => ({ isLoggedIn, isLoading, setIsLoggedIn }),
    [isLoggedIn, isLoading],
  )

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider")
  }
  return context
}
