"use client"

import { useMessageSnackbarStore } from "@/stores/ui/useMessageSnackbar"
import { Alert, AlertColor, Snackbar } from "@mui/material"

interface MessageSnackbarProps {
  message: {
    id: number
    message: string
    severity: AlertColor
  }
  offset: number
}

export function MessageSnackbar({ message, offset }: MessageSnackbarProps) {
  const removeMessage = useMessageSnackbarStore((state) => state.removeMessage)

  const handleClose = () => {
    removeMessage(message.id)
  }

  return (
    <Snackbar
      open
      autoHideDuration={6000}
      ClickAwayListenerProps={{ onClickAway: (event) => event.stopPropagation() }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      onClose={handleClose}
      sx={{
        marginBottom: `${offset * 70}px`,
        transition: "margin-bottom 0.3s ease",
        maxWidth: "90%",
        whiteSpace: "pre-line",
      }}
    >
      <Alert
        severity={message.severity}
        onClose={handleClose}
        sx={{
          height: "100%",
          width: "100%",
          boxShadow: 3,
        }}
      >
        {message.message}
      </Alert>
    </Snackbar>
  )
}
