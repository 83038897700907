"use client"

import { useConversation } from "@11labs/react"
import { useCurriculumByToken } from "@/hooks/curriculum/useCurriculumByToken"
import { useCurrentProfileStore } from "@/stores/data/useCurrentProfileStore"
import { keyframes } from "@emotion/react"
import styled from "@emotion/styled"
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import { useCallback } from "react"

import { agent } from "./chatPrompts"
import { useClientTools } from "./tools"

const wave = keyframes`
  0%, 100% {
    transform: scale(1.25);
  }
  50% {
    transform: scale(1.05);
  }
`
const AnimatedSpeaking = styled(AutoAwesomeOutlinedIcon)`
  animation: ${wave} 0.6s infinite cubic-bezier(0.25, 0.8, 0.25, 1);
  will-change: transform;
`

const AnimatedIdle = styled(AutoAwesomeOutlinedIcon)`
  animation: ${wave} 1.5s infinite cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
`

export function ConversationAI() {
  const { curriculum, isErrorCurriculum } = useCurriculumByToken()
  const { tools } = useClientTools()
  const currentProfile = useCurrentProfileStore((state) => state.currentPerson)

  const conversation = useConversation({
    onConnect: () => {},
    onDisconnect: () => {},
    onMessage: (message) => console.log("Mensagem:", message),
    onError: (error) => console.error("Erro:", error),
  })

  const startConversation = useCallback(async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true })
      const profileInfo = {
        candidateId: currentProfile?.id || undefined,
        candidateName: currentProfile?.name || "candidato ou candidata",
        isLogged: isErrorCurriculum,
        progress: curriculum?.progress ?? 0.0,
      }

      await conversation.startSession({
        agentId: `${process.env.NEXT_PUBLIC_CHATAI_API_KEY}`,
        overrides: {
          agent: agent(profileInfo, profileInfo.candidateName),
        },
        clientTools: tools,
      })
    } catch (error) {
      console.error("Falha ao iniciar a conversa:", error)
    }
  }, [
    conversation,
    currentProfile?.id,
    currentProfile?.name,
    curriculum?.progress,
    isErrorCurriculum,
    tools,
  ])

  const stopConversation = useCallback(async () => {
    await conversation.endSession()
  }, [conversation])

  return (
    <div style={{ position: "fixed", top: "50%", right: "1rem", zIndex: 100 }}>
      <Tooltip
        title={
          conversation.status === "connected" ? "Parar Conversa" : "Iniciar Conversa"
        }
      >
        <IconButton
          sx={{
            background:
              conversation.status === "disconnected"
                ? "linear-gradient(45deg, #2C39C8, #6B47FF)"
                : "linear-gradient(45deg, #8DC37E, #6BCC4A)",
            "&:hover": {
              background: "linear-gradient(45deg, #2C39C8, #6B47FF)",
              transform: "scale(1.3)",
            },
            color: "#fff",
            width: "3rem",
            height: "3rem",
          }}
          onClick={
            conversation.status === "connected" ? stopConversation : startConversation
          }
        >
          {conversation.status === "connected" ? (
            conversation.isSpeaking ? (
              <AnimatedSpeaking />
            ) : (
              <AnimatedIdle />
            )
          ) : (
            <AutoAwesomeOutlinedIcon />
          )}
        </IconButton>
      </Tooltip>
    </div>
  )
}
