"use client"

import { getJwtToken } from "@/cookies/jwtCookieHelper"
import useNotificationWebSocket from "@/hooks/websocket/useNotificationWebSocket"
import { useMessageSnackbarStore } from "@/stores/ui/useMessageSnackbar"
import { useEffect, useState } from "react"

export default function NotificationWebSocket() {
  const [id, setId] = useState<string | null>(null)
  const { message } = useNotificationWebSocket(id || "")
  const { successMessage } = useMessageSnackbarStore()

  useEffect(() => {
    const token = getJwtToken()
    if (token) {
      const userId = decodeTokenToUserId(token)
      setId(userId)
    }
  }, [])

  useEffect(() => {
    if (message) {
      successMessage(message)
    }
  }, [message, successMessage])

  function decodeTokenToUserId(token: string): string | null {
    try {
      const payload = JSON.parse(atob(token.split(".")[1]))
      return payload.preferred_username || null
    } catch {
      return null
    }
  }

  return null
}
